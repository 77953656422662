@import '../../scss/imports';

#csv-dialog {
  border-radius: rem(4px);
  padding-bottom: 0;

  #csv-dialog-title {
    padding-bottom: 0;
  }

  .error-body {
    margin-bottom: rem(30px);
  }

  ul {
    margin: 0;
    padding-bottom: rem(20px);

    li {
      margin-bottom: rem(8px);
    }
  }

  ul.errors {
    list-style: none;
    padding-left: 0;
  }

  ul.errors > li {
    margin-left: rem(10px);
  }

  ul.errors > li::before {
    content: '- ';
    margin-left: rem(-10px);
  }

  .md-dialog--centered {
    border-radius: rem(4px);
    width: rem(420px);

    .md-dialog-content {
      padding-bottom: 0;
    }

    .footer {
      padding: 0;
    }
  }

  .dismiss-error {
    width: 100%;
  }
}
