@import '../../scss/imports';

.create-vips {
  padding-bottom: rem(84px);

  .md-divider {
    margin-bottom: rem(8px);
  }

  .md-text-field-message-container {
    padding-right: rem(8px);
  }

  .names {
    margin-top: rem(20px);

    .title-name {
      font-family: $font-demi;
      font-size: rem(18px);
    }

    .name-fields {
      button {
        color: #aaa;
        display: none;
        transform: translate3d(-6px, -6px, 0);
      }

      .md-text-field.md-text {
        font-size: rem(16px);
      }

      &:nth-child(n+2) {
        .last:hover {
          button {
            display: inline-block;
          }
        }
      }

      .md-text-field.md-text {
        font-size: rem(16px);
      }
    }
  }

  .lots-title {
    margin-top: rem(32px);

    .title-lots {
      font-family: $font-demi;
      font-size: rem(18px);
    }

    .subtitle-lots {
      font-size: rem(18px);
    }
  }

  #duplicates-modal {
    .duplicates {
      margin-bottom: 0;
      padding-left: rem(20px);
    }
  }
}

.lot-select-all {
  margin-bottom: .5rem;

  label {
    color: $black;
    font-family: $font-demi;
    font-size: 1.5rem;
    padding-left: .25rem;
    
    i {
      margin-top: .4375rem;
      padding-left: .25rem;
    }

    span {
      margin-left: 1.25rem;
    }
  }
}
