@import '../../scss/imports';

.filter-chip-container {
  margin: rem(0 4px);
  min-height: rem(32px);
  position: relative;

  &:first-child {
    margin-left: 0;
  }
}

.filter-chip {
  border-radius: 2px 2px 0 0;
  cursor: pointer;
  position: relative;

  .filter-chip-header {
    align-items: center;
    background-color: #e0e0e0;
    border-radius: rem(26px);
    color: $text-color;
    display: flex;
    font-family: $font-demi;
    font-size: rem(14px);
    height: rem(32px);
    justify-content: space-between;
    padding: rem(0 6px 0 16px);
  }

  .list-container {
    max-height: rem(216px);
    overflow-y: scroll;
  }

  .apply-container {
    align-items: flex-end;
    display: flex;
    height: rem(44px);
    justify-content: flex-end;
    padding-right: rem(11px);

    .apply-filter {
      margin: 0;
    }
  }

  .chip-label,
  .chip-remove-button,
  .chip-icon {
    display: inline-block;
    vertical-align: middle;
  }

  .chip-icon,
  .chip-remove-button {
    height: rem(20px);
    text-align: center;
    width: rem(20px);

    .md-icon {
      font-size: rem(20px);
    }
  }

  .chip-close-icon {
    color: inherit;
    height: rem(24px);
    text-align: center;
    width: rem(24px);

    .md-icon {
      color: inherit;
      font-size: rem(24px);
    }
  }

  .chip-label {
    font-family: $font-regular;
    height: rem(32px);
    line-height: rem(32px);
    margin-right: rem(5px);
    text-transform: capitalize;
    white-space: nowrap;
  }

  .chip-remove-button {
    background: none;
    border: 0;
    border-radius: 50%;
    box-sizing: border-box;
    padding: 0;
  }

  &.removable {
    .filter-chip-header {
      background-color: $primary-color;
      color: #fff;
    }
  }

  &.expanded {
    animation: open 300ms cubic-bezier(.29, .76, .58, .89);
    box-shadow: $md-box-shadow;
    position: absolute;
    top: 0;
    width: rem(350px);
    z-index: 9;

    @include max-screen($smt) {
      height: 100%;
      left: 50%;
      max-width: calc(100vw - 16px);
      position: fixed;
      top: rem(112px);
      transform: translateX(-50%);
      width: 100%;
    }

    @include max-screen($xs) {
      top: rem(64px);
    }

    .filter-chip-header {
      background-color: $primary-color;
      border-radius: 2px 2px 0 0;
      color: #fff;
      height: rem(60px);
      padding: rem(0 16px);
    }

    .chip-label {
      font-family: $font-demi;
    }

    .filter-chip-dropdown {
      background-color: #fff;
      border-radius: 0 0 2px 2px;
      box-shadow: $box-shadow;
      max-height: rem(480px);
      overflow-y: auto;
      padding: rem(16px 5px);
      position: absolute;
      text-align: left;
      top: rem(60px);
      width: 100%;

      .search-container {
        padding: rem(0 11px);
      }

      .select-all-container {
        padding: rem(0 11px);
      }

      .md-selection-control-label {
        padding: rem(0 11px);
      }

      .chip-list {
        background-color: #fff;
        padding-top: 0;

        .md-btn--icon {
          height: rem(24px);
          width: rem(24px);

          .md-icon {
            color: $primary-color;
            font-size: rem(24px);
          }
        }

        .md-list-tile {
          height: rem(36px);
          padding-left: 0;

          .md-tile-text--primary {
            color: #000;
            font-family: $font-regular;
            font-size: rem(14px);
            font-weight: normal;
          }

          .md-tile-content--left-button {
            padding-left: rem(16px);
          }
        }
      }
    }

    .chip-section-header {
      color: #000;
      font-family: $font-demi;
      font-size: rem(14px);
      margin: 0;
    }

    .md-divider--text-field::after {
      background: $primary-color;
    }

    .md-text-field-icon-container {
      .md-icon {
        margin: 0;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      .md-text--theme-primary {
        color: $primary-color;
      }
    }
  }

  &.expand-left {
    right: 0;
  }

  &.expand-right {
    left: 0;
  }

  .md-list-control {
    .md-btn--icon {
      padding: 0;
    }
  }

  .md-selection-control-container {
    height: rem(36px);
  }

  .md-list-item {
    margin: 0;
  }
}

.ph-checkbox {
  .md-selection-control-label {
    color: #000;
    display: flex;
    font-family: $font-demi;
    font-size: rem(14px);
    height: 100%;
    width: 100%;

    span {
      flex-grow: 1;
    }

    .material-icons {
      font-size: rem(24px);
    }

    .md-btn--icon {
      color: $primary-color;
      height: rem(24px);
      margin-right: 0;
      width: rem(24px);
    }
  }

  .material-icons.md-icon {
    height: 100%;
    width: 100%;
  }
}
