@import '../../scss/imports';

#csv {
  .main-content {
    margin-top: rem(64px);
  }

  .card {
    box-shadow: $box-shadow;
    padding: rem(24px 24px 24px);
  }

  .dropzone {
    background-color: transparent;
    border-color: transparent;
    border-radius: rem(6px);
    border-style: dashed;
    border-width: rem(6px);
    cursor: pointer;
    outline: none;
    padding: rem(20px 20px 0 20px);
    transition: all .24s ease-in-out;

    &:hover {
      background-color: rgba(33, 150, 243, .03);
      border: 6px dashed $primary-color;
      transition: none;
    }
  }

  .dropzone-container {
    h2 {
      color: $secondary-text-color;
      font-family: $font-demi;
      margin: rem(0 0 0 0);
    }

    p {
      color: #000;
      font: rem(18px) $font-regular;
      margin: rem(24px 0);
    }

    .dropzone-message-container {
      margin: rem(8px 0);
      min-height: rem(20px);

      .dropzone-error {
        color: $priority-color;
      }
    }
  }

  .download-link {
    border: 2px solid $primary-color;
    border-radius: rem(3px);
    color: $primary-color;
    display: flex;
    height: rem(32px);
    justify-content: center;
    margin: 24px auto;
    padding: rem(16px);
    padding-left: 0;
    text-decoration: none;
    text-transform: uppercase;
    width: rem(240px);

    .md-icon-text {
      font: rem(16px) $font-demi;
      padding-left: rem(8px);
      text-transform: lowercase;
    }
  }
}
