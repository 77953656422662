@import '../../scss/imports';

.search-input-container {
  position: relative;

  input {
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 1rem;
    font-family: $font-regular;
    font-size: rem(14px);
    height: rem(32px);
    line-height: rem(32px);
    min-width: 100%;
    padding-left: rem(42px);
  }

  span {
    height: rem(24px);
    left: rem(8px);
    position: absolute;
    top: rem(4px);
    width: rem(24px);
  }
}
