/* APP COLORS */
$black: #000;
$white: #fff;
$primary-color: #2196f3;
$secondary-color: #f44336;
$priority-color: #f11a1a;
$label-color: #878787;
$disabled-color: #c3c3c3;
$text-color: #121212;
$secondary-text-color: rgba($black, .6);
$body-bg-color: #fafafa;
$header-background-color: #fff;
$header-action-button-color: $white;
$light-text-color: rgba($white, .8);

/* TEXTURES */
$border: 1px solid $secondary-text-color;
$border-radius: rem(4px);
$box-shadow: rem(0 2px 4px) rgba($black, .2);
$md-box-shadow: rem(0 2px 2px 0) rgba(0, 0, 0, .14), rem(0 1px 5px 0) rgba(0, 0, 0, .12), rem(0 3px 1px -2px) rgba(0, 0, 0, .2);

/* FONTS */
$font-ultra-light: 'AvenirNextLTW01-UltraLi', Arial, sans-serif;
$font-light: 'Avenir Next W01 Light', Arial, sans-serif;
$font-regular: 'AvenirNextLTW01-Regular', Arial, sans-serif;
$font-numbers: 'Avenir Next Cyr W00 Regular', Arial, sans-serif;
$font-demi: 'Avenir Next LT W01 Demi', Arial, sans-serif;
$font-heavy: 'AvenirNextLTW01-Heavy', Arial, sans-serif;

/* SCREEN SIZES */
$xxs: em(400);
$xs: em(480);
$sm: em(600);
$smt: em(800);
$md: em(960);
$lg: em(1280);
$xl: em(1440);
$ipad: em(768);
$landscape: em(1024);

$wrapper: rem(1200px);

/* PH OVERRIDES */
$ph-font-path: 'assets/fonts';
