@import '../../scss/imports';

.edit-vips {
  padding-bottom: rem(84px);

  .md-divider {
    margin-bottom: rem(8px);
  }

  .md-text-field-message-container {
    padding-right: rem(8px);
  }

  .names {
    .title-name {
      font-family: $font-demi;
      font-size: rem(18px);
    }

    .name-fields {
      button {
        display: none;
        top: 40%;
      }

      input {
        font-size: rem(16px);
      }
    }

    .name-fields:nth-child(n+2) {
      .last:hover {
        button {
          display: inline-block;
        }
      }
    }
  }

  .lots-title {
    margin-top: rem(14px);

    .title-lots {
      font-family: $font-demi;
      font-size: rem(18px);
    }

    .subtitle-lots {
      font-size: rem(18px);
      margin: rem(12px 0 24px 0);
    }
  }

  .lots-list {
    .lot-li {
      margin-bottom: rem(8px);
    }
  }
}
