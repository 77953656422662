@import '../../scss/imports';

.footer {
  background-color: #f7f7f7;
  border-top: 1px solid #e0e0e0;
  bottom: 0;
  height: rem(64px);
  left: 0;
  position: fixed;
  width: 100%;

  .footer-actions {
    height: rem(64px);

    button {
      + button {
        margin-left: rem(16px) !important;
      }
    }
  }
}
