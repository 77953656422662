@import '../../scss/imports';

.event-cards-container {
  padding-bottom: rem(64px);
}

#snackbar {
  background-color: #323232;
  box-shadow: rem(0) rem(4px) rem(4px) rgba(0, 0, 0, .2);
  margin-bottom: 72px;
  min-height: rem(56px);
  width: rem(786px);

  p {
    margin: auto rem(10px);
  }

  button {
    color: #7c4dff;
    font-weight: bold;
  }
}
